
export const GET_LATEST_BLOCKS = 'GET_LATEST_BLOCKS';
export const BLOCK_ERROR = 'BLOCK_ERROR';

export const GET_LATEST_TRANSACTIONS = 'GET_LATEST_TRANSACTIONS';
export const GET_RECENT_TRANSACTIONS = 'GET_RECENT_TRANSACTIONS';
export const TRANSACTION_ERROR = 'TRANSACTION_ERROR';
export const GET_BLOCK_DETAIL = 'GET_BLOCK_DETAIL';
export const GET_TRANS_DETAIL = 'GET_TRANS_DETAIL';
export const GET_SEARCH_RESULT = 'GET_SEARCH_RESULT';
