import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

const Holder = ({ holdersData, page, offset }) => {
  console.log(page, offset, 'alslsl')

  let holders = '';

  if (Object.keys(holdersData).length > 0) {
    const now = new Date();
    holders = holdersData.map((saver, idx) => {
      // const date = new Date(saver.createdAt);
      // let second = (now - date) / 1000;
      // let day = parseInt(second / 3600 / 24);
      // let hour = parseInt(second / 3600) % 24;
      // let minute = parseInt(second / 60) % 60;
      // second = parseInt(second) % 60;

      let decimal = 0;
      let value = saver.value / 1000000000000000000;
      const numStr = String(value);
      if (numStr.includes('.')) {
        decimal = numStr.split('.')[1].length;
      }

      return <tr className="naji-TableBodyRow_row" key={idx}>
        <td className="naji-width-100"><span>{(page-1) * offset + idx + 1}</span></td>
      <td className="naji-width-200"><Link to={`/address/${saver.address}`} className="naji_link"><span>{saver.address.slice(0, 5)}...{saver.address.slice(saver.address.length - 5, saver.address.length)}</span></Link></td>
        <td className="naji-width-200"><span>{decimal>4?value.toFixed(4):value}</span></td>
      </tr>
    });
  }

  return (
    <Fragment>
      <div className="naji-LandingPage_epochList">
        <div className="naji-EpochList_epochListContainer">
          <div className="naji-Table_tableContainer">
            <div className="naji-Table_title">
              <div className="naji-DividerWithTitle_dividerContainer">
                <div className="naji-DividerWithTitle_line">

                </div>
                <span className="naji-DividerWithTitle_title">Holders</span>
              </div>
            </div>
            <div className="naji-Table" style={{display: 'flex', justifyContent: 'center'}}>
              <div style={{width: "fit-content"}}>
                <table>
                  <thead className="naji-TableHead_headContainer">
                    <tr>
                      <th className="naji-width-100">No</th>
                      <th className="naji-width-200">Address</th>
                      <th className="naji-width-200">Value</th>
                    </tr>
                  </thead>
                  <tbody className="naji-TableBody_bodyContainer">
                    {holders.length == 0 ? <h3 style={{ textAlign: "center", marginTop: "50px" }}>Not found</h3> : holders}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="naji-ShowMoreButtonDecorator_root">
              <a className="naji-LocalizedLink_link" href="#">
                  <span className="naji-ShowMoreButtonDecorator_showMoreButton">show more</span>
              </a>
          </div> */}
      </div>
    </Fragment>
  );
};

Holder.propTypes = {
  transaction: PropTypes.array.isRequired,
};

export default connect(null)(Holder);
