import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Block from "./Block";
import Transaction from "./Transaction";
import { getLatestBlocks, getSearchResult } from "../../actions/block";
import { getLatestTransactions } from "../../actions/transaction";
import { history } from "../../store";
import logo from "../../img/ancientcoin.png";
import "../../style.css";
import { Input } from "antd";
import { ethers, BigNumber } from "ethers";
import axios from "axios";
import api from "../../utils/api";

const Dashboard = ({
  getLatestBlocks,
  getLatestTransactions,
  block,
  transaction,
  searchResult,
}) => {
  const [blocksData, SetBlocksData] = useState({});
  const [transactionsData, SetTransactionsData] = useState({});
  const [price, setPrice] = useState(0);
  const [transCount, setTransCount] = useState(0);
  const [avGasPrice, setAvGasPrice] = useState(0);
  const [circulSupply, setCirculSupply] = useState(0);
  const [holders, setHolders] = useState(0);
  const totalSupply = 200000000;

  useEffect(() => {
    getLatestBlocks();
    getLatestTransactions();
    axios
      .get("https://ancientscoin.com/ancient/users/getAcprice")
      .then((result) => {
        setPrice(result.data.acPrice);
      });

    api.get("/acchains/transCount").then((res) => {
      if (res && res.data.total) {
        setTransCount(res.data.total);
      }
    });

    api.get("/acchains/holders").then((res) => {
      setHolders(res.data.holders);
    });
    const provider = new ethers.providers.JsonRpcProvider(
      "https://ancientscoin.com/rpc"
    );
    provider
      .getBalance("0x96678f674940775fd2ee1007f9044f0687338912")
      .then((val) => {
        setCirculSupply(
          200000000 -
            parseFloat(ethers.utils.formatEther(val)).toFixed(4) +
            200000000
        );
      });
  }, []);

  useEffect(() => {
    SetBlocksData(block.blocks);
  }, [block]);

  useEffect(() => {
    SetTransactionsData(transaction.transactions);
    let trans = transaction.transactions;
    let gasSum = 0;
    for (let i = 0; i < trans.length; i++) {
      if (trans[i].gasPrice) {
        gasSum += Number(trans[i].gasPrice);
      }
    }
    if (trans.length > 0) {
      setAvGasPrice(gasSum / trans.length / 1000000000);
    }
  }, [transaction]);

  useEffect(() => {
    SetBlocksData([searchResult.block_result]);
    SetTransactionsData(searchResult.transaction_result);
  }, [searchResult]);
  //   setTimeout(() => {
  //       getLatestBlocks();
  //   }, 4000);

  const searchBlocksTransactions = () => {
    let value = document
      .getElementById("searchInfo")
      .value.trim()
      .toLowerCase();
    if (value.length == 42 && value.slice(0, 2) == "0x") {
      value = ethers.utils.getAddress(value);
      history.push(`/address/${value}`);
      history.go(`/address/${value}`);
    } else if (value.length == 66 && value.slice(0, 2) == "0x") {
      history.push(`/tx/${value}`);
      history.go(`/tx/${value}`);
    } else if (!value) {
      getLatestBlocks();
      getLatestTransactions();
    } else {
      history.push(`/block/${value}`);
      history.go(`/block/${value}`);
    }
  };

  return (
    <section className="naji-container">
      <header className="naji-header">
        <div className="naji-header_logoContainer">
          <a className="" href="/">
            <img src={logo} className="naji-header_logoImg" alt="logo" />
          </a>
        </div>
        <div className="naji-header_titleContainer">
          <a className="naji-LocalizedLink_link" href="#">
            <b>Ancient Coin</b>
            <span> BlockChain Explorer</span>
          </a>
        </div>
        <div className="naji-Header_triangleSign">
          <div className="naji-Header_straightLine"></div>
          <div className="naji-Header_triangle">
            <div className="naji-Header_innerTriangle"></div>
          </div>
        </div>
      </header>
      <div>
        <div class="naji-content_generalInfo">
          <div class="naji-LocalizedLink_link">
            <b>ANC Price: </b>
            <span>${price.toFixed(2)}</span>
          </div>
          <div class="naji-LocalizedLink_link">
            <b>Transactions: </b>
            <span>{transCount}</span>
          </div>
        </div>
        <div class="naji-content_generalInfo">
          <div class="naji-LocalizedLink_link">
            <b>Med Gas price: </b>
            <span>{avGasPrice} Gwei</span>
          </div>
          <div class="naji-LocalizedLink_link">
            <b>Latest Block:</b>
            <span>
              {blocksData && blocksData.length > 0 && blocksData[0].number}
            </span>
          </div>
        </div>
        <div class="naji-content_generalInfo">
          <div class="naji-LocalizedLink_link">
            <b>Marketcap on Ancient </b>
            {/* <span>${(circulSupply * price).toFixed(2)}</span> */}
            <span>
              $
              {(circulSupply * price).toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })}
            </span>
          </div>
          <div class="naji-LocalizedLink_link">
            <b>Circulating Supply: </b>
            {/* <span>{circulSupply.toFixed(2)} ANC</span> */}
            <span>
              {circulSupply.toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })}{" "}
              ANC
            </span>
          </div>
        </div>
        <div class="naji-content_generalInfo">
          <div class="naji-LocalizedLink_link">
            <b>Total Supply </b>
            {/* <span>${(circulSupply * price).toFixed(2)}</span> */}
            <span>
              {totalSupply.toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })}{" "}
              ANC
            </span>
          </div>
          <div class="naji-LocalizedLink_link">
            <b>
              <a className="naji-LocalizedLink_link" href="holders">
                Holders:{" "}
              </a>
            </b>
            {/* <span>{circulSupply.toFixed(2)} ANC</span> */}
            <span>{holders}</span>
          </div>
        </div>
      </div>

      <div className="naji-searchContainer">
        <h2 className="naji-Search_searchTitle">Search</h2>
        <div className="naji-Search_searchContent">
          <div className="naji-Search_searchInput naji-SimpleFormField_root">
            <div className="naji-SimpleFormField_inputWrapper">
              <Input
                className="naji-SimpleInput_input"
                placeholder="Search blocks, addresses and transactions"
                id="searchInfo"
                onPressEnter={(event) => {
                  searchBlocksTransactions();
                }}
              />
            </div>
          </div>
          <button
            className="naji-Search_searchButton naji-SimpleButton_root"
            onClick={() => searchBlocksTransactions()}
          >
            <div className="naji-Search_searchButtonIcon">
              <div className="naji-Search_searchButtonInner"></div>
            </div>
          </button>
        </div>
      </div>

      <Block block={blocksData} />

      <Transaction transaction={transactionsData} />
      {/* <Block block={block.blocks} />

            <Transaction transaction={transaction.transactions} /> */}
    </section>
  );
};

Dashboard.propTypes = {
  getLatestBlocks: PropTypes.func.isRequired,
  getSearchResult: PropTypes.func.isRequired,
  block: PropTypes.object.isRequired,
  transaction: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  searchResult: state.block.searchResult,
  block: state.block,
  transaction: state.transaction,
});

export default connect(mapStateToProps, {
  getLatestTransactions,
  getLatestBlocks,
  getSearchResult,
})(Dashboard);
